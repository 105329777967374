import React, {useEffect, useState, useRef} from 'react';
import './Nearall.css';
import IndexTopClient from '../IndexPage/IndexTopClient';
import IndexTestimonial from '../IndexPage/IndexTestimonial';
import MetaTag from '../../components/MetaTag';
import Nearchennairesort from './Nearchennairesort';



  const Nearchennai = () => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleContent = () => {
        setIsExpanded(!isExpanded);
    };

    const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
  }, []);


  return (
    <>
    <MetaTag noindex={false} titles="100+ Corporate Offsite Venues near Chennai | Corporate Team Outing Places near Chennai" metakeywords="corporate offsite venues near Chennai, corporate offsite near Chennai, corporate team outing near Chennai, corporate outing near Chennai, corporate team outing places near Chennai, corporate event venues near Chennai" metaDescriptions="Explore the Best Corporate Offsite Venues Near Chennai with CYJ and Discover Amazing Corporate Team Outing Places. Corporate offsites near Chennai offer the perfect blend of work and leisure, providing a refreshing escape from the daily grind, these destinations promote team bonding, boost productivity, and create lasting memories. Experience a stress-free, rejuvenating break with your colleagues at the finest offsite locations near Chennai. Engage in Exciting Corporate Team Building Activities and Strengthen Bonds with Your Team Like Never Before. For Bookings, Call us at 8130781111 or 8826291111." hrefLinks={window.location.href}/>
    <div>
      <div className="neardelhi-topbanner">
          <div className="left-content">
           <img src='image/text-head2.png'/>
            <p>
            Corporate offsites near Chennai offer a refreshing escape for busy professionals. A weekend team outing is a great way to boost employee morale. Blending work with leisure is essential, as these retreats reduce stress and foster meaningful relaxation.
            </p>
          </div>
          <div className="right-content">
            <div className="video-bubble">
              <video className="bubble-video" ref={videoRef} autoPlay muted loop>
                <source src="/videos/nearchennai.mp4" type="video/mp4" />
              </video>
              <h2>Near Chennai</h2>
            </div>
          </div>
      </div>
      <div className='neardelhiresorts-section'>
        <h1>EXPLORE THE BEST CORPORATE OFFSITE VENUES NEAR CHENNAI FOR A MEMORABLE TEAM RETREAT</h1>
         <p className={`content ${isExpanded ? 'expanded' : ''}`}>
         Corporate offsites are the perfect way to strengthen team bonding and enhance collaboration in a stress-free environment. These events allow teams to disconnect from their daily work routine and engage in meaningful interactions, fostering better relationships and improved teamwork. By stepping away from the workplace, employees can immerse themselves in a refreshing setting, boosting their creativity, motivation, and overall job satisfaction. Whether it's for brainstorming sessions, leadership workshops, or a rejuvenating retreat, a corporate offsite plays a crucial role in driving long-term success.<br></br><br></br>
         Chennai, being a major corporate hub in South India, offers a variety of venues ideal for hosting corporate offsite events. The city's proximity to scenic coastal towns, cultural landmarks, and tranquil resorts makes it a prime location for team outings. Whether you prefer a beachfront retreat in Mahabalipuram, a vibrant and artistic escape in Puducherry, or a heritage-inspired experience in Vellore, there are plenty of destinations to explore for an unforgettable offsite experience.<br></br><br></br>
         One of the biggest advantages of hosting a corporate offsite is the opportunity to break the monotony of office life. When employees step out of their usual workspaces, they return with fresh perspectives, innovative ideas, and a renewed sense of enthusiasm. A relaxed and engaging atmosphere encourages creativity, fosters teamwork, and enhances problem-solving skills, which ultimately benefits the organization. Additionally, employees feel valued when given a chance to unwind and connect with colleagues in a refreshing setting, leading to improved morale and productivity.<br></br><br></br>
         For those seeking a seamless and well-organized Corporate Offsite Near Chennai, CYJ is your trusted corporate event planner. With years of experience in curating exceptional corporate offsite events, we take care of every detail, from venue selection to team-building activities, ensuring a hassle-free and enjoyable experience.<br></br><br></br>
         Plan Your Next Team Outing at the Best Corporate Offsite Venues Near Chennai for a Unique and Refreshing Experience!


            </p>
            {/* <button onClick={toggleContent} className="read-more-btn">
                {isExpanded ? 'Read Less ↑' : 'Read More ↓'}
            </button> */}
            <div onClick={toggleContent} className="read-more-btn">
              <span></span>
               <i className={`fa-solid fa-circle-arrow-${isExpanded ? 'up' : 'down'}`}></i>
              <span></span>
            </div>
           
        <Nearchennairesort/>

        <p className='bottom-neardelhi-content'>
                

              
            </p>
      </div>
      <div style={{backgroundColor:'#2e073f', paddingTop:'1rem',
        backgroundImage: 'linear-gradient(150deg, #2E073F, #76308d, #2E073F)'
      }} >
        <IndexTestimonial/>
      </div>
      <IndexTopClient/>
    </div>
    </>
    
  )
}

export default Nearchennai

