import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';
import MobileNavBar from './MobileNavBar';
import axios from 'axios';
import Swal from 'sweetalert2';
const NavBar = () => {

    const [locationData, setLocationData] = useState([]);
    useEffect(() => {
        const fetchApiData = async () => {
            try {
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-location');
                const data = await result.json();
                setLocationData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchApiData();
    }, []);


    const [serdta, setServdata]=useState([]);
    useEffect(()=>{
        const servicedata = async()=>{
            try{
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-service');
                const data = await result.json();
                setServdata(data);
            }catch(error){
                console.log(error);
            }
        }
        servicedata();
    }, []);


    useEffect(()=>{
        function mobmenufun(){
            let mobileMenuheader = document.querySelector('.mobileMenuheader');
            const links = document.querySelectorAll('.linkm');
            let shobtn = document.querySelector('.menubarboxs');
            let mbcross = document.querySelector('.mbcross');
            shobtn.addEventListener('click', function(){
                mobileMenuheader.classList.add('active');
            });
            mbcross.addEventListener('click', function(){
                mobileMenuheader.classList.remove('active');
            });

            links.forEach(link=>{
                link.addEventListener('click', function(){
                    mobileMenuheader.classList.remove('active');
                });
              });

            }
            mobmenufun();
    },[]);

  

    // useEffect(() => {
    //     const links = document.querySelectorAll('a');
    //     links.forEach(link => {
    //       link.addEventListener('click', scrollToTop);
    //     });
      
    //     return () => {
    //       links.forEach(link => {
    //         link.removeEventListener('click', scrollToTop);
    //       });
    //     };
    // }, []);
      
    // const scrollToTop = () => {
    //     window.scrollTo({
    //       top: 0,
    //       behavior: 'smooth'
    //     });
    // };
    
    // window.onscroll = function() {
    //     scrollFunction();
    // };
    
   var overlayShown = false;

const scrollFunction = () => {
    const logoContainerImg = document.querySelector(".logoCantainer img");
    // const headerLinks = document.querySelectorAll(".header ul li a"); 

    if (!overlayShown && (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100)) {
        document.querySelector(".header").style.backgroundColor = "#2E073F";
        // if (logoContainerImg) {
        //     logoContainerImg.src = "../image/whitelogo.png";  
        // }
        // headerLinks.forEach(link => link.style.color = "#ffffff"); 
        overlayShown = true;
    } else if (overlayShown && (document.body.scrollTop <= 100 && document.documentElement.scrollTop <= 100)) {
        document.querySelector(".header").style.backgroundColor = "transparent";
        // if (logoContainerImg) {
        //     logoContainerImg.src = "../image/logo.png";  
        // }
        // headerLinks.forEach(link => link.style.color = "#e2b4ba"); 
        overlayShown = false;
    }
};
    
    
    const [showForm, setShowForm] = useState(false);

    const handleQuickQueryClick = () => {
      setShowForm(true);
    };
  
    const handlePopXClick = () => {
      setShowForm(false);
    };


//contact form start

let urlsGet = window.location.href;
    
const [errors, setError] = useState('');
const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    location: '',
    pageUrl: urlsGet,
});

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const website_name = 'Corporate Planner';
const indiaDateTime = new Date().toLocaleString('en-IN', {
  timeZone: 'Asia/Kolkata',
  hour12: false,            
  weekday: 'long',         
  year: 'numeric',          
  month: 'long',        
  day: 'numeric',       
  hour: '2-digit',         
  minute: '2-digit',       
  second: '2-digit'   
});

const formattedDateTime = indiaDateTime.replace(/,/g, ''); 

let contact = '';
if (formData['name'] !== '') contact += `Name:-${formData['name']}, `;
if (formData['mobile'] !== '') contact += `Mobile:-${formData['mobile']}, `;
if (formData['email'] !== '') contact += `Email:-${formData['email']}, `;
if (formData['location'] !== '') contact += `Location:-${formData['location']}, `;
if (formData['pageUrl'] !== '') contact += `Url:-${formData['pageUrl']}, `;
contact += `Date:-${formattedDateTime}`;
const messages = '';

const formdta = {
  website_name: website_name,
  contact: contact,
  message: messages
};

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const result = await axios.post('https://leads.hotelstourandtravel.com/api/cyj-leads-insert', formdta);
        Swal.fire(result.data.message)
        .then((r) => {
            if (r.isConfirmed) {
                
                document.querySelectorAll('.formslidef input, .formslidef textarea').forEach(element => {
                    element.value = '';
                });

                window.location.reload();
            }
        });
        
    } catch (error) {
        setError(error.response.data);
    }
};

//contact form end
  return (
    <>
    <header className='header'>
        {/* logo section Start */}
    <div className="logoCantainer">
    <NavLink to="/"><img src="../image/whitelogo.png" alt="" /></NavLink> 
    </div>
    

    <ul className="destopMenu">
        <li>
            <NavLink to="/" className="mainLink" activeClassName="active">Home</NavLink>
        </li>
        <li>
            <NavLink to="about" className="mainLink" activeClassName="active">About</NavLink>
        </li>

        <li className="submenuIndia" ><div className='nolinkheader' activeClassName="active" >India</div>
            <div className="indiamenubox">
                <div className="arrowind"></div>
                <div className="arrowind2"></div>
                <ul className='indiamenu'>
                    {
                        locationData
                        .filter(itm=>itm.country == 'India')
                        .map((itm, index)=>(
                            <li key={index}><NavLink to={itm.location_url}>{itm.location}</NavLink></li>
                        ))
                    }     
                    <li className='viewallheader' ><NavLink to="/india-offsite-tours">View All</NavLink></li>            
                </ul>
            </div>
        </li>
        <li className="submenuIndia"><div className='nolinkheader' activeClassName="active">International</div>
            <div className="indiamenubox" id='intermenubox'>
                <div className="arrowind arrowInter"></div>
                <div className="arrowind2 arrowInter2"></div>
                <ul className='indiamenu'>
                    {
                        locationData
                        .filter(itm=>itm.country == 'International')
                        .map((itm, index)=>(
                            <li key={index}><NavLink to={itm.location_url}>{itm.location}</NavLink></li>
                        ))
                    }    
                    <li className='viewallheader' ><NavLink to="/international-offsite-tours" >View All</NavLink></li>        
                </ul>
            </div>
        </li>
        <li className="submenuIndia" ><div className='nolinkheader' activeClassName="active" >Our Services</div>
            <div className="indiamenubox" id='indiamenubox'>
                <div className="arrowind arrowservice"></div>
                <div className="arrowind2 arrowservice2"></div>
                <ul className='indiamenu'>
                    {
                        serdta
                        .filter((i)=>i.status === 1)
                        .map((serv, index )=>(
                            <li key={index}><NavLink to={serv.service_url} className="subLink">{serv.service}</NavLink></li>
                        ))
                    }   
                     <li className='viewallheader' ><NavLink to="/corporate-event-services" className="subLink">View All</NavLink></li>
                </ul>
            </div>
        </li>
        
        <li>
            <NavLink to="our-portfolio" className="mainLink" activeClassName="active">Our Portfolio </NavLink>
        </li>
        
        <li className="submenuIndia" ><div className='nolinkheader' activeClassName="active"  >Corporate Offsite Venues</div>
            <div className="indiamenubox offsitebox" id='indiamenubox'>
                <div className="arrowind arrowservice"></div>
                <div className="arrowind2 arrowservice2"></div>
                <ul className='indiamenu offsitemenu'>
                    <li>
                        <NavLink to="corporate-offsite-venues-near-delhi" className="mainLink" activeClassName="active">Corporate Offsite Near Delhi </NavLink>
                    </li>
                    <li>
                        <NavLink to="corporate-offsite-venues-near-mumbai-and-pune" className="mainLink" activeClassName="active">Corporate Offsite Near Mumbai & Pune </NavLink>
                    </li>
                    <li>
                        <NavLink to="corporate-offsite-venues-near-chandigarh" className="mainLink" activeClassName="active">Corporate Offsite Near Chandigarh </NavLink>
                    </li>
                    <li>
                        <NavLink to="corporate-offsite-venues-near-bangalore" className="mainLink" activeClassName="active">Corporate Offsite Near Bangalore </NavLink>
                    </li>
                    <li>
                        <NavLink to="corporate-offsite-venues-near-chennai" className="mainLink" activeClassName="active">Corporate Offsite Near Chennai </NavLink>
                    </li>
                    
                </ul>
            </div>
        </li>
        {/* <li>
            <NavLink to="corporate-offsite-venues-near-delhi" className="mainLink" activeClassName="active">Corporate Offsite Near Delhi </NavLink>
        </li> */}
        <li>
            <NavLink to="contact" className="mainLink" activeClassName="active">Contact</NavLink>
        </li>
    </ul>
    <button className="quickquery" onClick={handleQuickQueryClick}>Send Query</button>
    <div className="menubarboxs">
    <i className="fa-solid fa-bars"></i>
    </div>
</header>
    <MobileNavBar />
    {showForm && (
    <div className="sendquerybox">
        <div className="innersendquery">
        
       
        <i className="fa-solid fa-x popx" onClick={handlePopXClick}></i>
      
            <div className="formimgbx">
                <img src="image/carton.png" alt="from" />
            </div>
           <form className='popupformbx formslidef' onSubmit={handleSubmit}>
                <h2>Get in Touch with Us</h2>
                <p>Plan Your Escape with Us!</p>
                <div className="forminputfield">
                    <i className="fa-solid fa-user"></i>
                    <input type="text" name="name" value={formData.name} placeholder="Name" onChange={handleChange}/>
                </div>
                <p style={{color:'red', fontSize:'.7rem', }}>{errors.name}</p>
                <div className="forminputfield">
                    <i className="fa-solid fa-phone"></i>
                    <input type="text" name="mobile" value={formData.mobile} placeholder="Mobile" onChange={handleChange}/>
                </div>
                <p style={{color:'red', fontSize:'.7rem', }}>{errors.mobile}</p>
                <div className="forminputfield">
                    <i className="fa-solid fa-envelope"></i>
                    <input type="email" name="email" value={formData.email} placeholder="Email" onChange={handleChange}/>
                </div>
                <p style={{color:'red', fontSize:'.7rem', }}>{errors.email}</p>
                <div className="forminputfield">
                    <i className="fa-solid fa-envelope"></i>
                    <input type="text" name="location" value={formData.location} placeholder="Location" onChange={handleChange}/>
                </div>
                <p style={{color:'red', fontSize:'.7rem', }}>{errors.location}</p>
                
                <button>Submit</button>
           </form>
        </div>
   
    </div>
     )}
    </>
  )
}

export default NavBar;
