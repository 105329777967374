import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './indexpageCss/IndexLocation.css';

const IndexLocation = (props) => {
    const [lokdata, setData]=useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const locationGet = props.lokdt;
 
    useEffect(()=>{
        const lokfetch = async ()=>{
            try{
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-location');
                const data = await result.json();
                setIsLoading(false);
                setData(data);
            }catch(error){
                console.warn(error);
            }
        }
        lokfetch();
    }, []);
if(isLoading){
    <p>Loading...</p>
}



    let locations = lokdata.map(item => ({
    id: item.id,
    location: item.location,
    category: item.country,
    location_url: item.location_url,
    image: `https://backend.corporateeventplanner.in/storage/location-gallery/${item.location_banner}`
}));



const countryActive = locations.find(x => x.location === locationGet)?.category || null;

const [selectedCategory, setCategory] = useState(countryActive);

useEffect(() => {
    setCategory(countryActive);
}, [countryActive]);

const categoryClick = (category) => {
    setCategory(category);
};
useEffect(() => {
    setCategory(countryActive === null ? 'India': countryActive);
}, [locationGet]);


const [ldta, setLdata] = useState([]);

useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await Promise.all(
          lokdata.map(async (item) => {
            try {
              const response = await fetch(`https://backend.corporateeventplanner.in/api/cyj-location-other-data/${item.id}`);
              const result = await response.json();
              return {
                location_id: item.id,
                thumbnailImg: result
              };
            } catch (error) {
              console.error(`Failed to fetch data for id ${item.id}:`, error);
              return null;
            }
          })
        );
        setLdata(results.filter(result => result !== null));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (lokdata.length > 0) {
      fetchData();
    }
  }, [lokdata]);



    return (
        <>
            <section className="indexlocationSection" style={{ backgroundImage: `url('image/backgroundlocation.jpg')` }} >
                <div className="Heading">
                    <span className="topheading">Experience the best Offsite</span>
                    <div className="sbheadings">
                        <p>Lorem ipsum dolor</p>
                        <h2> Locations</h2>
                        <p>Lorem ipsum dolor</p>
                    </div>
                </div>
                <div className="innerindexlocationSection">
                    <ul className="locationindinterul">
                        <li
                            id="indiaClick"
                            className={selectedCategory === 'India' ? 'active' : ''}
                            onClick={() => categoryClick('India')}
                        >
                            India
                        </li>
                        <li
                            id="interClick"
                            className={selectedCategory === 'International' ? 'active' : ''}
                            onClick={() => categoryClick('International')}
                        >
                            International
                        </li>
                    </ul>
                    <div className="locationindxBox">
                        {/* slider Start  */}
                        {
                        
                        locationGet == "undefined" ?
                        locations.map((location, index) => (
                    
                            location.category === selectedCategory && (
                                <Link to={location.location_url} className="locationindxCard" key={index}>
                                    <div className="locationindxCardImage">
                                    {
                                        ldta
                                            .filter(i => i.location_id === location.id)
                                            .flatMap(i => i.thumbnailImg)
                                            .flatMap(y => y.ThumbnailImage)
                                            .map((x, idx) => (
                                              <img
                                                key={idx} // Use index as key here
                                                src={`https://backend.corporateeventplanner.in/storage/location-gallery/${x.location_thumbnail}`}
                                                alt={location.location}
                                              />
                                        ))
                                    }
                                        <div className="loklinktext">
                                            <h3>{location.location}</h3>
                                        </div>
                                    </div>
                                </Link>
                            )
                        ))

                        : 

                        locations.filter(x=>x.location !== locationGet)
                        .map((location, index) => (
                    
                            location.category === selectedCategory && (
                                <Link to={"../"+location.location_url} className="locationindxCard" key={index}>
                                    <div className="locationindxCardImage">
                                    {
                                        ldta
                                            .filter(i => i.location_id === location.id)
                                            .flatMap(i => i.thumbnailImg)
                                            .flatMap(y => y.ThumbnailImage)
                                            .map((x, idx) => (
                                              <img
                                                key={idx} // Use index as key here
                                                src={`https://backend.corporateeventplanner.in/storage/location-gallery/${x.location_thumbnail}`}
                                                alt={location.location}
                                              />
                                        ))
                                    }
                                        <div className="loklinktext">
                                            <h3>{location.location}</h3>
                                        </div>
                                    </div>
                                </Link>
                            )
                        ))

                        }
                        {/* slider Start end */}
                    </div>
                    {/* <Link to="location" id="locationSlideButton">
                        View All Offsite Location <i className="fa-solid fa-arrow-right-long"></i>
                    </Link> */}
                </div>
            </section>
        </>
    );
};

export default IndexLocation;
